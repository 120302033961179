import React, { useCallback, useEffect } from "react";
import { AuthContextProvider, useContextSignIn } from "./src/utils/AuthContextProvider";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Input } from "@mui/material";
const backendAPI = process.env.GATSBY_NLP_API;

let darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        label: {
          textTransform: "none", // This will remove the default uppercase transformation
        },
      },
    },
  },
});
darkTheme = responsiveFontSizes(darkTheme);

// TODO : add newsletter API if needed for authenticated user or not authenticated user for asking email input to newsletter

// Showing the newsletter popup model form if user is authenticated and localstorage is enabled for newsletter for end users
// export const shouldShowNewsletterPopup = () => {
//     if (localStorage.getItem('newsletter') === 'true') {
//         return true;
//     } else {
//         return false;
//     }
// };
// determine if time interval is passed for showing newsletter popup model form ( based on time interval form API Call)

const Wrapper = ({ element }) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const props = useContextSignIn();

  useEffect(() => {
   
    if (!Boolean(localStorage.getItem("newsletter"))) {
      (async () => {
        const time = setTimeout(() => {
          setOpen(true);
        }, 2000);

        const user = Cookies.get("youtube_analyzer_user");
       
        if (user) {
          const userData = JSON.parse(user);
          handleNewsletterSubs(userData.email);
        }
        return () => clearTimeout(time);
      })();
    }
  }, []);

  const handleNewsletterSubs = async (email) => {
    try {
      // const user = Cookies.get("youtube_analyzer_user");
      // const userData = JSON.parse(user);
      const response = await fetch(`https://analyzer-nlp-api-production-eac8.up.railway.app/add-newsletter-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      });
      const responseData = await response.json();
      if (!!responseData) {
        localStorage.setItem("newsletter", true);
      } else {
        throw new Error(responseData.error);
      }
    } catch (error) {
      toast.error("Error adding newsletter", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AuthContextProvider>
        {element}
        {/* <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {Boolean(Cookies.get("youtube_analyzer_user")) ? "Would you like to subscribe to newsletter ?" : "Add your email to our newsletter"}
          </DialogTitle>
          {!Boolean(Cookies.get("youtube_analyzer_user")) && (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Input required onChange={(e) => setEmail(e.target.value)} fullWidth type="email" name="email" id="email" placeholder="Enter your email" />
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => {handleNewsletterSubs(email); handleClose();}} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}
      </AuthContextProvider>
    </ThemeProvider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <Wrapper element={element} />;
};

// Add the onRouteUpdate function
export const onRouteUpdate = ({ location }) => {
  const links = document.querySelectorAll(".customLink");

  links.forEach((link) => {
    if (link.getAttribute("href") === location.pathname) {
      link.classList.add("active");
    } else {
      link.classList.remove("active");
    }
  });
};
